<template>
  <div
    ref="readMore"
    :style="expanderStyle"
    class="ReadMore overflow-hidden relative transition-all"
  >
    <slot />
    <div
      v-if="!shorter && contentHeight > -1"
      class="absolute bottom-0 cursor-pointer w-full type-xs-medium desk:type-sm-medium underline"
      :class="{
        readMore: !expanded
      }"
      @click="toggle"
    >
      <div class="py-8">
        <component
          :is="asButton ? 'div':'span'"
          :class="{
            'btn btn--secondary w-full': asButton
          }"
        >
          {{ expanded ? showOpenText : showClosedText }}
        </component>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onBeforeUnmount } from 'vue';
const { $t } = useNuxtApp();

interface Props {
  maxHeight?: number;
  showInStoryblok?: boolean;
  closedText?: string;
  openText?: string;
  asButton?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  maxHeight: 100,
  showInStoryblok: false,
  openText: '',
  closedText: '',
  asButton: false,
});

// withDefaults doesn't work with $t, so we have to use computed instead
const showOpenText = computed(()=> {
  return props.openText !== '' ? props.openText : $t('productPage.truncateTextCtaOpen');
});

const showClosedText = computed(()=> {
  return props.closedText !== '' ? props.closedText : $t('productPage.truncateTextCtaClosed');
});

const route = useRoute();
let isInStoryblokAdmin = (route.query && route.query._storyblok && route.query._storyblok !== '');
if (props.showInStoryblok) {
  isInStoryblokAdmin = false;
}

const readMore = ref<HTMLDivElement | null>(null);
const contentHeight = ref(-1);
const longer = ref(true); // if we should have read more at all
const shorter = ref(false); // if content is shorter
const expanded = ref(false);

const expanderStyle = computed(()=> {
  if (contentHeight.value === -1) {
    return {
      maxHeight: `${props.maxHeight}px`,
    };
  }
  if (!shorter.value && !expanded.value) {
    return {
      height: `${props.maxHeight}px`,
    };
  }
  if (!shorter.value && expanded.value) {
    return {
      height: `${contentHeight.value + 48}px`,
    };
  }
  return {};
});

const toggle = () => {
  expanded.value = !expanded.value;
};

onMounted(()=> {
  if (!isInStoryblokAdmin) {
    checkSize();
    window.addEventListener('resize', checkSize);
  } else {
    // always show all in storyblok
    shorter.value = true;
    contentHeight.value = 0;
  }
});
onBeforeUnmount(()=> {
  window.removeEventListener('resize', checkSize);
});

const checkSize = () => {
  const contentHeightIn = readMore.value?.scrollHeight;
  if (contentHeightIn) {
    contentHeight.value = contentHeightIn;

    if (contentHeightIn <= props.maxHeight) {
      longer.value = false;
      shorter.value = true;
    } else {
      longer.value = true;
      shorter.value = false;
    }

  } else {
    console.warn('Could not get height');
  }
};
</script>

<style scoped lang="postcss">
.readMore {
  @apply pt-24;
  background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,.9) 30%,rgba(255,255,255,1) 100%);
}
</style>
